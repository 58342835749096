import Vue from 'vue'
import Vuetify from 'vuetify'
import IconComponent from '../components/IconComponent.vue'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)
export default new Vuetify({

    icons: {
        component: IconComponent,
        values: {
            dropdown: {
                component: IconComponent,
                props: {
                    icon: "expand-more"
                }
            },
            poweroff: {
                component: IconComponent,
                props: {
                    icon: 'mdi-power-standby'
                }
            },
            unfold: {
                component: IconComponent,
                props: {
                    icon:'mdi-unfold-more-horizontal'
                }
            },
            menu: {
                component: IconComponent,
                props: {
                    icon:'mdi-menu'
                }
            },
            prev: {
                component: IconComponent,
                props: {
                    icon:'mdi-chevron-left'
                }
            },
            next: {
                component: IconComponent,
                props: {
                    icon:'mdi-chevron-right'
                }
            },
            sort: {
                component: IconComponent,
                props: {
                    icon:'mdi-sort'
                }
            },
            clear: {
                component: IconComponent,
                props: {
                    icon:'mdi-close'
                }
            },
            close: {
                component: IconComponent,
                props: {
                    icon:'mdi-close'
                }
            },
            checkboxOn: {
                component: IconComponent,
                props: {
                    icon: 'mdi-checkbox-marked'
                }
            },
            checkboxOff: {
                component: IconComponent,
                props: {
                    icon: 'mdi-checkbox-blank-outline'
                }
            }
        }
    },

    theme: {
        themes:{
            light: {
                primary: "red"
            },
            dark: {
                primary: "red"
            }
        }
    }
})
