import Vue from "vue";
import * as Luxon from "luxon";
import { Buffer } from "buffer";
import ApexCharts from "vue-apexcharts";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./plugins/router";
import { VueMaskDirective } from "v-mask";
import axios from "axios";

// @ts-ignore
window.Buffer = Buffer;

const token = localStorage.getItem("instream_panel_login_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

Vue.directive("mask", VueMaskDirective);
window["siteId"] = "instream.panel";
window["service"] = {
  base: "https://panel.instream.audio",
  ui: "https://panel.instream.audio/instream.panel/ui",
  api: "https://panel.instream.audio/instream.panel/api",
};

// Set default timezone from local storage (it'll also update later on in Navigation)
const timezone =
  localStorage.getItem("current_radio_timezone") ||
  "America/Argentina/Buenos_Aires";
Luxon.Settings.defaultZone = timezone;

Vue.config.productionTip = false;

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
  // Registrar el error en la consola para depuración (opcional)
  console.error("Error capturado:", err);

  // Puedes manejar el error aquí, por ejemplo, enviarlo a un servidor o mostrar una alerta
  // Si quieres evitar que los errores se muestren, simplemente no hagas nada aquí
};

(Vue.config as any).silent = true;

Vue.use(ApexCharts);
//Vue.use(VueMask)
Vue.component("apexchart", ApexCharts);

// @ts-ignore
let params: any = {
  vuetify,
  router,
  render: (h) => h(App),
};
new Vue(params).$mount("#app");
