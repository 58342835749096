import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let base = "/ui"
if (location.pathname.startsWith("/instream.panel"))
	base = "/instream.panel/ui"

export default new Router({
	mode: 'history',
	base,
	routes: [

		{
			path: '/login',
			name: 'login',
			component: () => import("../views/Panel/Login.vue")
		},


		{
			path: '/history/:id',
			name: 'history',
			component: () => import("../views/History.vue")
		},
		/*
		{
			path: '/programming/:id',
			name: 'programming',
			component: ()=> import("../views/ProgrammingIframe.vue")
		},
		{
			path: '/widget/programming/:id',
			name: 'w_programming',
			component: ()=> import("../views/ProgrammingIframe.vue")
		},*/

		{
			path: '/dashboard/covers',
			name: 'covers',
			component: () => import("../views/Panel/Covers/List.vue")
		},

		{
			path: '/dashboard/widget/1',
			name: 'widget_1',
			component: () => import("../views/Panel/Widget1.vue")
		},

		{
			path: '/dashboard/cover/:create',
			name: 'covers_create',
			component: () => import("../views/Panel/Covers/Create.vue")
		},

		{
			path: '/dashboard/cover/edit/:id',
			name: 'covers_edit',
			component: () => import("../views/Panel/Covers/Create.vue")
		},


		{
			path: '/dashboard',
			name: 'dashboard',
			redirect: '/dashboard/chat',
			component: () => import("../views/Panel/Main.vue")
		},
		{
			path: '/dashboard/chat',
			name: 'dashboard_chat',
			component: () => import("../views/Panel/Chat.vue")
		},
		{
			path: '/dashboard/analytics',
			name: 'dashboard_analytics',
			component: () => import("../views/Panel/Main.vue")
		},

		{
			path: '/dashboard/analytics_program',
			name: 'dashboard_analytics_program',
			component: () => import("../views/Panel/ProgramAnalytics.vue")
		},

		{
			path: '/dashboard/player',
			name: 'dashboard_player',
			redirect: '/dashboard/chat',
			component: () => import("../views/Panel/Player.vue")
		},

		{
			path: '/dashboard/settings',
			name: 'dashboard_settings',
			component: () => import("../views/Panel/Settings.vue")
		},

		{
			path: '/dashboard/admin/settings',
			name: 'dashboard_adminsettings',
			component: () => import("../views/Panel/AdminSettings.vue")
		},

		{
			path: '/dashboard/sponsors',
			name: 'dashboard_sponsors',
			component: () => import("../views/Panel/Sponsors.vue")
		},
		{
			path: '/dashboard/sponsor/create',
			name: 'dashboard_sponsorcreate',
			component: () => import("../views/Panel/SponsorCreate.vue")
		},

		{
			path: '/dashboard/sponsor/edit/:id',
			name: 'dashboard_sponsoredit',
			component: () => import("../views/Panel/SponsorCreate.vue")
		},

		{
			path: '/dashboard/links',
			name: 'dashboard_links',
			component: () => import("../views/Panel/Links.vue")
		},
		{
			path: '/dashboard/link/create',
			name: 'dashboard_linkcreate',
			component: () => import("../views/Panel/LinkCreate.vue")
		},

		{
			path: '/dashboard/link/edit/:id',
			name: 'dashboard_linkedit',
			component: () => import("../views/Panel/LinkCreate.vue")
		},

		{
			path: '/dashboard/ads',
			name: 'dashboard_ads',
			component: () => import("../views/Panel/Ads.vue")
		},
		{
			path: '/dashboard/ad/create',
			name: 'dashboard_adcreate',
			component: () => import("../views/Panel/AdCreate.vue")
		},

		{
			path: '/dashboard/ad/edit/:id',
			name: 'dashboard_adedit',
			component: () => import("../views/Panel/AdCreate.vue")
		},

		{
			path: '/dashboard/programming',
			name: 'dashboard_programs',
			component: () => import("../views/Panel/Programs.vue")
		},
		{
			path: '/dashboard/programming/create',
			name: 'dashboard_programcreate',
			component: () => import("../views/Panel/ProgramCreate.vue")
		},

		{
			path: '/dashboard/programming/edit/:id',
			name: 'dashboard_programedit',
			component: () => import("../views/Panel/ProgramCreate.vue")
		},

		{
			path: '/dashboard/plans',
			name: 'dashboard_plans',
			component: () => import("../views/Panel/Plans.vue")
		},
		{
			path: '/dashboard/push',
			name: 'dashboard_push',
			component: () => import("../views/Panel/Push.vue")
		},
		{
			path: '/dashboard/push/create',
			name: 'dashboard_pushcreate',
			component: () => import("../views/Panel/PushCreate.vue")
		},
		{
			path: '/dashboard/push/edit/:id',
			name: 'dashboard_pushedit',
			component: () => import("../views/Panel/PushCreate.vue")
		},


		{
			path: '/dashboard/podcasts',
			name: 'dashboard_podcast',
			component: () => import("../views/Panel/Podcast.vue")
		},
		{
			path: '/dashboard/podcast/create',
			name: 'dashboard_podcastcreate',
			component: () => import("../views/Panel/PodcastCreate.vue")
		},
		{
			path: '/dashboard/podcast/edit/:id',
			name: 'dashboard_podcastedit',
			component: () => import("../views/Panel/PodcastCreate.vue")
		},
		{
			path: '/dashboard/podcast/:id/sources',
			name: 'dashboard_podcastsources',
			component: () => import("../views/Panel/PodcastSource.vue")
		},

		{
			path: '/dashboard/podcast/:pid/sources/create',
			name: 'dashboard_podcastsources_create',
			component: () => import("../views/Panel/PodcastSourceCreate.vue")
		},
		{
			path: '/dashboard/podcast/sources/edit/:id',
			name: 'dashboard_podcastsources_create',
			component: () => import("../views/Panel/PodcastSourceCreate.vue")
		},

		{
			path: '/dashboard/polls',
			name: 'dashboard_polls',
			component: () => import("../views/Panel/Polls.vue")
		},
		{
			path: '/dashboard/users',
			name: 'dashboard_users',
			component: () => import("../views/Panel/Users.vue")
		},
		{
			path: '/dashboard/radios',
			name: 'dashboard_radios',
			component: () => import("../views/Panel/Radios.vue")
		},
		{
			path: '/dashboard/radio/create',
			name: 'dashboard_radiocreate',
			component: () => import("../views/Panel/RadioCreate.vue")
		},
		// Añadidos para proyectos
		{
			path: '/dashboard/projects',
			name: 'dashboard_projects',
			component: () => import("../views/Panel/Projects.vue")
		},
		{
			path: '/dashboard/projects/create',
			name: 'dashboard_projectcreate',
			component: () => import("../views/Panel/ProjectCreate.vue")
		},

		{
			path: '/dashboard/poll/create',
			name: 'dashboard_pollcreate',
			component: () => import("../views/Panel/PollCreate.vue")
		},

		{
			path: '/dashboard/poll/result/:id',
			name: 'dashboard_pollresult',
			component: () => import("../views/Panel/PollResult.vue")
		},

		{
			path: '/dashboard/poll/:id/questions',
			name: 'dashboard_pollquestions',
			component: () => import("../views/Panel/PollQuestions.vue")
		},

		{
			path: '/dashboard/poll/:id/results',
			name: 'dashboard_pollresults',
			component: () => import("../views/Panel/PollResults.vue")
		},

		{
			path: '/dashboard/poll/question/edit/:id',
			name: 'dashboard_pollquestionedit',
			component: () => import("../views/Panel/PollQuestionCreate.vue")
		},


		{
			path: '/dashboard/poll/:pid/question/create',
			name: 'dashboard_pollquestions',
			component: () => import("../views/Panel/PollQuestionCreate.vue")
		},



		{
			path: '/dashboard/poll/edit/:id',
			name: 'dashboard_polledit',
			component: () => import("../views/Panel/PollCreate.vue")
		},

		{
			path: '/dashboard/user/create',
			name: 'dashboard_usercreate',
			component: () => import("../views/Panel/UserCreate.vue")
		},

		{
			path: '/dashboard/user/edit/:id',
			name: 'dashboard_radioedit',
			component: () => import("../views/Panel/UserCreate.vue")
		},

		{
			path: '/dashboard/radio/edit/:id',
			name: 'dashboard_radioedit',
			component: () => import("../views/Panel/RadioCreate.vue")
		},
		{
			path: '/dashboard/projects/edit/:id',
			name: 'dashboard_projectedit',
			component: () => import("../views/Panel/ProjectCreate.vue")
		},

		{
			path: '/dashboard/plan/edit/:id',
			name: 'dashboard_planedit',
			component: () => import("../views/Panel/PlanCreate.vue")
		},

		{
			path: '/dashboard/plan/create',
			name: 'dashboard_plancreate',
			component: () => import("../views/Panel/PlanCreate.vue")
		},


		{
			path: '/analytic/:id',
			name: 'base',
			component: () => import("../views/Analytics.vue")
		},
		{
			path: '/radio/d3b39bf13d',
			name: 'radio',
			component: () => import("../views/RadioList.vue")
		},
		{
			path: '/get/:id',
			name: 'base2',
			component: () => import("../views/Analytics.vue")
		},
		{
			path: '/live/:id',
			name: 'base3',
			component: () => import("../views/Analytics.vue")
		},
		{
			path: '/programming/:id',
			name: 'programming',
			component: () => import("../views/Widget.vue")
		},
		{
			path: '/widget/programming/:id',
			name: 'w_programming',
			component: () => import("../views/Widget.vue")
		},
		{
			path: '*',
			name: 'notfound',
			component: () => import("../views/NotFound.vue")
		}
	]
})
