<template>
	<v-app style='background:transparent !important'>
		<icons />
		<router-view :key="$route.path + num"></router-view>
	</v-app>
</template>

<style>
.login-semi-1 {
	background-color: rgba(0, 0, 0, 0.8);
}

.login-semi-2 {
	background-color: rgba(0, 0, 0, 0.6);
}

.login-semi-3 {
	background-color: rgba(0, 0, 0, 0.4);
}

.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light {
	color: inherit !important;
	caret-color: inherit !important;
}

html,
body {
	overflow-y: auto !important;
}

.no-padding {
	padding: 0 !important;
}

.upper {
	text-transform: uppercase;
}

.f-400 {
	font-weight: 400;
}

.f-600 {
	font-weight: 600;
}

.v-card__title {
	font-size: 1.1rem;
	font-weight: 400;
	word-break: break-all;
}

.v-application {
	line-height: 1.3;
}

.v-application .red {
	background-color: #f8192c !important;
	border-color: #f8192c !important;
}

.kpi-1 {
	font-size: 0.85em;
}

.kpi-1 .v-icon {
	font-size: 2.8em !important;
	vertical-align: top !important;
}

.kpi-1 .v-card__text h3,
.kpi-1 .v-card__text h4 {
	width: auto;
}

.nowrap {
	white-space: nowrap;
}

.whitespace-pre-wrap {
	white-space: pre-wrap;
}

.upper {
	text-transform: uppercase;
}

/* esto afecta a los gráficos de dona */
.v-application {
	font-family: 'Poppins', 'Roboto', sans-serif !important;
}

.v-application h2 {
	font-weight: 500;
}

svg text {
	font-family: Poppins, Roboto, sans-serif !important;
}

.apexcharts-datalabels-group text {
	text-transform: uppercase;
}

/* esto afecta a los gráfico de area/líneas/barras */
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
	fill: #f64e60;
}
</style>
<script>
import Icons from './components/Icons'
//import IconsTwo from './components/IconsWidget'

export default {
	components: {
		Icons
	},
	data() {
		this.vars = {
			num: 0
		}
		window.forceRender = () => {
			this.vars.num++
		}
		return this.vars
	}
}
</script>
